@import '../sass/bootstrap/custom.scss';
@import 'theme';
@import 'button';
@import 'checkbox';
@import 'Paginate';

.App {
  min-height: 100vh;
}

.z-255 {
  z-index: 255;
}

.z-254 {
  z-index: 254;
}

.fit-content {
  max-width: fit-content;
}

.org-dropdown {
  div.dropdown-menu.show {
    max-height: 10rem !important;
    overflow-y: auto !important;
  }
}

.side-nav {
  position: fixed;
  width: 100%;
  bottom: 0;
}

.side-nav .flex-column {
  flex-direction: row !important;
}

.space-evenly {
  justify-content: space-evenly;
}

.text-transparent {
  color: transparent;
}

.big-checkbox {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.user-avatar-container {
  float: 'left';
  width: '100%';
}

.user-avatar {
  width: 30px;
  height: 30px;
  background: linear-gradient(45deg, #fbdb86 0%, #f7797d 100%);
  font-size: 12px;
  line-height: 14px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  float: right;
}

.leads-header {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
}
.loading {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.8);
  z-index: 10000;
}
.search-wrapper {
  background: #f2f2f2;
  border: 1px solid #f2f2f2;
  border-radius: 4px;

  width: 40%;

  .search-text {
    width: 85%;
  }

  &.active {
    border: 1px solid $primary-color;
    background: #fff;
    box-shadow: 0px 0px 12px 4px rgba(52, 209, 182, 0.1);

    .form-control,
    .form-control:focus {
      border-color: transparent !important;
      box-shadow: none !important;
    }
  }
}
.selected-checkmark {
  float: right;
  color: #34d1b6;
}

.success-alert {
  background-color: $primary-color-light;
  box-shadow: 0 0 1px;
}

.list-item-grey {
  background-color: $grey-secondary;
  border-top: 1px solid darken($grey-secondary, 10%);
  height: 90px;
}

.small-list-item-grey {
  background-color: $grey-secondary;
  border-top: 1px solid darken($grey-secondary, 10%);
  height: 30px;
}

@media (max-width: 768px) {
  .search-wrapper {
    flex: 1;
  }

  .list-item-grey {
    height: max-content;
    min-height: 90px;
  }
}

.import-csv-table th {
  min-width: 10em;
  max-width: 15em;
}

.import-csv-dropdown-toggle {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: inherit;
}

.import-csv-dropdown-menu {
  min-width: inherit;
  width: inherit;
}

.import-csv-dropdown-item {
  width: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (min-width: 768px) {
  .leads-header {
    background-color: $primary-color-light;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
  }
}

@media (min-width: 992px) {
  .side-nav {
    height: 100vh;
    position: relative;
  }

  .side-nav .flex-column {
    flex-direction: column !important;
  }
}

.blink {
  animation: blinker 1.5s linear infinite;
}

.numberSetUpLabel {
  padding: 10px;
  &:hover {
    background: rgba(128, 128, 128, 0.1);
  }
}

.numberSetUpRadio {
  display: none !important;

  input {
    display: none;
  }
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
