@import 'theme';

.chrono-item {
  width: 90%;
  box-sizing: border-box;
  position: relative;
  text-align: left;
  width: 100%;
}

.chrono-section {
  word-wrap: break-word;
  align-items: flex-start;
  background-color: white;
  border-radius: 4px;
  display: flex;
  justify-content: flex-start;
  text-align: left;
  width: 100%;
}

.chrono-title {
  font-size: 1rem;
  font-weight: 600;
  margin-top: 0.5rem;
  text-align: left;
  width: 95%;
  margin: 0px;
  color: #505050;
}

.chrono-sub-title {
  font-size: 0.85rem;
  font-weight: 600;
  margin: 0;
  text-align: left;
  width: 97%;
  color: $primary-color;
}
