@import 'theme';

@mixin base-button($background-color) {
  color: #fff;
  background-color: $background-color;
  border-color: $background-color;
  padding: 5px 25px;
  white-space: nowrap;
}

@mixin button-effect-reset($color, $background-color, $border-color) {
  &:hover,
  &:disabled,
  &:active,
  &:focus {
    outline: none !important;
    color: $color !important;
    background-color: $background-color !important;
    border-color: $border-color !important;
    box-shadow: none !important;
  }
}

@mixin base-border-button($border-color, $color: #000) {
  border: 2px solid $border-color;
  color: $color;
  background-color: $white;
  padding: 5px 25px;
  @include button-effect-reset($color, $white, $border-color);
  &:hover {
    box-shadow: none !important;
  }
}

@mixin focus-button($shadow-color) {
  outline: 0;
  box-shadow: 0 0 0 0.2rem lighten($shadow-color, 10%);
}

.btn-sigma-circle {
  border-radius: 90px;
  border-width: 1px;
  width: 90px;
  height: 90px;
  font-size: 32px;
  color: gray;
  outline: none;
  &:hover,
  &:focus,
  &:active {
    outline: none;
  }
}
.btn-sigma-circle-small {
  border-radius: 60px;
  border-width: 1px;
  width: 60px;
  height: 60px;
  font-size: 24px;
  color: gray;
  outline: none;
  &:hover {
    color: black;
  }
  &:focus,
  &:active,
  &:hover {
    outline: none;
  }
}

.btn-sigma-circle.red {
  border-color: red;
  color: red;
}

.btn-sigma-circle.green {
  border-color: green;
  color: green;
}

.btn-sigma-white {
  color: #000;
  background-color: $white;
  border: 0px;
  padding: 5px 25px;
  &:hover,
  &:focus,
  &:active {
    outline: none;
  }
}

.btn-sigma-green-border {
  @include base-border-button($primary-color-dark);
}

.btn-sigma-green {
  @include base-button($primary-color);
  border: 2px solid $primary-color-dark;
  &:hover {
    @include base-button(darken($primary-color, 10%));
  }
}

.btn-sigma-red-border {
  @include base-border-button($danger-color);
}

.btn-sigma-purple-border {
  @include base-border-button($secondary-color);
}

.btn-sigma-muted-grey-border {
  @include base-border-button($muted-grey-color, $muted-grey-color);
}

.btn-sigma-white-border {
  color: #000;
  background-color: $white;
  border: 1px solid black;
  padding: 6px 28px;
  &:hover,
  &:focus,
  &:active {
    outline: none;
  }
}

.btn-sigma-white-border {
  color: #000;
  background-color: $white;
  border: 1px solid black;
  padding: 6px 28px;
  &:hover,
  &:focus,
  &:active {
    outline: none;
  }
}

.btn-sigma-primary {
  @include base-button($primary-color);

  &:disabled {
    background-color: darken($primary-color, 10%);
    border-color: $primary-color;
  }
}

.btn-sigma-primary:not(:disabled):not(.disabled).active,
.btn-sigma-primary:not(:disabled):not(.disabled):active,
.show > .btn-sigma-primary.dropdown-toggle {
  @include base-button(darken($primary-color, 10%));
}

.btn-sigma-primary.focus,
.btn-sigma-primary:focus {
  @include focus-button($primary-color);
}

.btn-sigma-primary:hover {
  @include base-button(darken($primary-color, 10%));
}

.btn-sigma-secondary {
  @include base-button($secondary-color);
}

.btn-sigma-secondary:not(:disabled):not(.disabled).active,
.btn-sigma-secondary:not(:disabled):not(.disabled):active,
.show > .btn-sigma-secondary.dropdown-toggle {
  @include base-button(darken($secondary-color, 10%));
}

.btn-sigma-secondary.focus,
.btn-sigma-secondary:focus {
  @include focus-button($secondary-color);
}

.btn-sigma-secondary:hover {
  @include base-button(darken($secondary-color, 10%));
}

.btn-sigma-success {
  @include base-button($success-color);
}

.btn-sigma-success:not(:disabled):not(.disabled).active,
.btn-sigma-success:not(:disabled):not(.disabled):active,
.show > .btn-sigma-success.dropdown-toggle {
  @include base-button(darken($success-color, 10%));
}

.btn-sigma-success.focus,
.btn-sigma-success:focus {
  @include focus-button($success-color);
}

.btn-sigma-success:hover {
  @include base-button(darken($success-color, 10%));
}

.btn-sigma-red {
  @include base-button($danger-color);
  &:hover {
    @include base-button(darken($danger-color, 10%));
  }
}

.btn-sigma-link {
  text-decoration: none;
  color: inherit;
  &:hover {
    text-decoration: none;
    color: inherit;
  }
}

@mixin grey-dropdown-effect {
  color: $muted-grey-color !important;
  background-color: $white !important;
  border: 2px solid $muted-grey-color !important;
  box-shadow: none !important;
}

.show > .btn-primary.dropdown-toggle {
  @include grey-dropdown-effect;
}

.grey-dropdown {
  min-width: 162px;
  .dropdown-menu.show {
    padding: 0 !important;
  }
}
.grey-dropdown-toggle {
  @include base-border-button($muted-grey-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  color: $muted-grey-color;
  &:hover,
  &:focus,
  &:active,
  &:visited {
    @include grey-dropdown-effect;
  }
}

.btn-no-styling {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  &:active,
  &:focus {
    outline: none;
  }
}
