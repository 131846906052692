.fc-button-primary {
  background: #34d1b6 !important;
  color: white;
  --fc-button-border-color: white;
}

.fc .fc-toolbar-title {
  margin-left: 1em;
  font-size: 1em !important;
  display: inline-block;
}
