.paginate-wrapper {
  display: flex;
  justify-content: space-evenly;
  padding-top: 10px;

  .paginate {
    .page-link {
      color: black;
      border: transparent;
      background-color: white;
      font-weight: 500;
    }
    .page-item.active .page-link {
      background-color: white;
      font-weight: 500;
      color: #25ac94;
    }
    .page-item.disabled {
      visibility: hidden;
    }
  }
  .page-view {
    display: flex;
    flex-direction: row;

    .page-size-title {
      font-size: 14px;
      padding-top: 2px;
      font-weight: 500;
    }
    .select-dropdown,
    .select-dropdown * {
      margin: 0;
      padding: 0;
      position: relative;
      box-sizing: border-box;
    }
    .select-dropdown {
      position: relative;
    }
    .select-dropdown select {
      font-size: 14px;
      font-weight: 500;
      font-weight: normal;
      max-width: 100%;
      padding: 0px 30px 0px 10px;
      border: none;
      background-color: transparent;
      color: #25ac94;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }
    .select-dropdown select:active,
    .select-dropdown select:focus {
      outline: none;
      box-shadow: none;
    }
    .select-dropdown:after {
      content: '';
      position: absolute;
      top: 25%;
      right: 8px;
      width: 0;
      height: 0;
      margin-top: -2px;
      border-top: 5px solid #aaa;
      border-right: 5px solid transparent;
      border-left: 5px solid transparent;
    }
  }
}
