@import 'theme';
$checkbox-primary-length: 1.6em;
$checkbox-small-length: 1em;

@mixin checkbox($length) {
  input {
    width: $length;
    height: $length;
    z-index: 1;
    cursor: pointer;

    &:checked ~ label::before {
      background-color: $primary-color !important;
      border-color: $primary-color !important;
    }
  }

  label {
    &:after {
      top: -1%;
      background: 100%/100% 100% no-repeat;
      height: $length;
      width: $length;
    }
    &:before {
      top: -1%;
      height: $length;
      width: $length;
      background-color: lighten($grey-color, 50%) !important;
    }
  }
}

.checkbox-sigma-primary {
  @include checkbox($checkbox-primary-length);
}

.checkbox-sigma-small {
  height: $checkbox-small-length !important;
  width: $checkbox-small-length !important;
  min-width: $checkbox-small-length !important;
  min-height: $checkbox-small-length !important;
  @include checkbox($checkbox-small-length);
}
