@import '../theme';

.bs-tooltip-top .arrow::before {
  border-top-color: $grey-color !important;
}
.bs-tooltip-bottom .arrow::before {
  border-bottom-color: $grey-color !important;
}
.bs-tooltip-left .arrow::before {
  border-left-color: $grey-color !important;
}
.bs-tooltip-right .arrow::before {
  border-right-color: $grey-color !important;
}
.tooltip-inner {
  color: $grey-color !important;
  background-color: white;
  border: 2px solid $grey-color;
}

.preview-tooltip .tooltip-inner {
  white-space: pre-line;
  text-align: left;
}
