$primary-color: #34d1b6;
$primary-color-light: lighten(saturate(adjust-hue($primary-color, -1), 3.61), 44.12);
$primary-color-dark: darken(saturate(adjust-hue($primary-color, 0), 3.61), 14.12);
$secondary-color: #907bf0;
$success-color: #e6a647;
$accent-one: #165bdf;
$muted-grey-color: #b3b3b3;
$grey-color: #666666;
$grey-secondary: #f8f8f8;
$danger-color: #eb5757;
$warm-color: #ffb340;
$primary-font: 'Roboto', 'Arial', 'sans-serif';
$white: #ffffff;

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.medium-purple {
  color: mediumpurple;
}

body {
  font-family: $primary-font;
}

a {
  color: $accent-one;
}

a:hover {
  color: darken($accent-one, 10%);
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: $primary-color;
}

.navbar-brand-sigma-mobile {
  display: none;
  max-width: 130px;
}

.navbar-brand-sigma {
  display: none;
  max-width: 130px;
}

@mixin pretty-scroll {
  &::-webkit-scrollbar {
    width: 6px;
    height: 12px;
  }

  &::-webkit-scrollbar-track {
    background: $grey-secondary;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: $muted-grey-color;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: darken($muted-grey-color, 10%);
  }
}

.top-nav-org-list {
  @include pretty-scroll;
}

.big-checkbox:checked {
  background-color: $primary-color;
}

.big-checkbox:before {
  background-color: $primary-color;
}

.text-sigma-primary {
  color: $primary-color;
}

.text-sigma-secondary {
  color: $secondary-color;
}

.text-sigma-muted {
  color: $muted-grey-color;
}

.text-sigma-grey {
  color: $grey-color;
}

.text-sigma-danger {
  color: $danger-color;
}

.text-sigma-yellow {
  color: $success-color;
}

.text-underline {
  text-decoration: underline;
}

.bg-sigma-primary {
  background-color: rgb($primary-color, 1);
}

.bg-sigma-light-primary {
  background-color: lighten(saturate(adjust-hue($primary-color, -1), 3.61), 44.12);
}

.bg-sigma-secondary {
  background-color: rgb($secondary-color, 1);
}

.bg-sigma-light-secondary {
  background-color: lighten(saturate(adjust-hue($secondary-color, -1), 6.12), 26.08);
}

.bg-sigma-light {
  background-color: lighten($grey-color, 54.9);
}

.bg-sigma-accent-one {
  background-color: rgb($accent-one, 1);
}

.bg-sigma-grey-secondary {
  background-color: rgb($grey-secondary, 1);
}

.rbt-input {
  background-color: lighten($grey-color, 54.9);
  border: 0px;
}

.font-weight-medium {
  font-weight: 500;
}

.badge-sigma-primary {
  color: #fff;
  background-color: rgb($primary-color, 1);
}

.badge-sigma-secondary {
  color: #fff;
  background-color: rgb($secondary-color, 1);
}

.big-checkbox:checked {
  background-color: #5cd6b9;
  color: white;
  fill: white;
}

.big-checkbox:checked {
  background-color: $primary-color;
  color: white;
  fill: white;
}

.side-nav-sigma {
  border-top: 1px solid #dee2e6;
}

.hover-pointer {
  &:hover {
    cursor: pointer;
  }
}

.side-nav-link-sigma {
  color: darken($grey-color, 1.96);
  font-weight: 500;
  font-size: 10px;
  padding-bottom: 1rem !important;
  padding-top: 1rem !important;
  padding: 8px;
}

.side-nav-text-sigma {
  display: block;
}

.side-nav-link-sigma:hover {
  color: darken($grey-color, 6.96);
}

.side-nav-link-sigma.active,
.side-nav-link-sigma.active .side-nav-icon-sigma svg {
  color: $primary-color;
  fill: $primary-color;
  background: lighten(desaturate(adjust-hue($primary-color, -1), 29.72), 39.41);
}

.side-nav-icon-sigma svg {
  display: block;
  overflow: visible;
  margin: auto;
}

.side-nav-icon-sigma svg {
  fill: lighten($grey-color, 31.76);
}

.side-nav-icon svg {
  fill: lighten($grey-color, 31.76);
}

.side-nav-icon-sigma svg:hover {
  fill: lighten($grey-color, 25);
}

.main-sigma {
  border-radius: 0;
  min-height: 100vh;
}

.card-sigma {
  color: $grey-color;
}

.card-header {
  font-size: 18px;
}

.header-card-sigma {
  .card-header {
    font-size: 14px;
  }
  .card-header .big {
    font-size: 36px;
  }
  .card-body {
    font-size: 12px;
  }
}

.card-link {
  font-size: 12px;
}

.card-upcoming {
  .card-header {
    background-color: #ebfbf8;
  }
}

.overdue {
  .task-item-name,
  .task-item-icon,
  .task-item-date {
    color: $danger-color;
  }
}

.task-item {
  background-color: #f8f8f8;
  border: none;
  height: 60px;
  border-bottom: 1px #eee solid;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.6);

  .item-date {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
}

.task-automated-block {
  display: inline-block;
  width: 100%;
  height: auto;
  text-align: center;
  color: $grey-color;
  padding: 10px;
  background-color: $grey-secondary;
  border-radius: 0px 0px 0.5rem 0.5rem;
  margin-top: 5px;

  span {
    font-family: inherit;
  }
}

.task-automated-icon {
  display: inline-block;
  color: $grey-color;
  size: 18px;
  overflow: hidden;

  // Center the farobot icon
  svg {
    margin-bottom: -0.1rem !important;
  }
}

.list-group-item-sigma {
  background-color: #f8f8f8;
  border: none;
  border-bottom: 1px #eee solid;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.6);
}

.item-date-header {
  padding: 0;
  text-align: right;
}

.task-detail-btn {
  border-radius: 21px;
  width: 42px;
  height: 42px;
  margin: auto;

  .btn-sigma-primary {
    padding: 0;
  }

  span {
    display: none;
  }
}

.task-item:last-child {
  border-bottom: none;
}

.task-item-date {
  color: $muted-grey-color;
}

.task-item-name,
.task-item-contact,
.task-item-opportunity {
  font-size: 16px;
}

.task-item-review {
  color: #907bf0;
}

.history-card {
  color: $grey-color;
  font-size: 18px;
}

.lead-item-title {
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
}

.history-title {
  font-size: 32px;
  font-weight: 500;
  color: darken($grey-color, 70%);
}

.history-item {
  color: $grey-color;
}

.history-item.selected {
  color: $secondary-color;
}

.timeline_container {
  position: relative;
}

.timeline-label_container {
  position: absolute;
  bottom: 0;
}

.timeline-label {
  font-size: 8px;
  color: lighten($grey-color, 49.02%);
  font-weight: 500;
}

.timeline-icon:hover {
  cursor: pointer;
}

.timeline-svg {
  overflow: visible;
}

.timeline-text {
  font-size: 10px;
}

a.nav-item.nav-link {
  font-size: 18px;
  color: gray;
  width: 50%;
  text-align: center;
}
.css-ma7t54-MuiPaper-root-MuiDialog-paper {
  margin: 0px !important;
  box-shadow: none; /* Red shadow */
  border-radius: 20rem; /* Rounded border */
}

.css-1fbcoca-MuiPaper-root-MuiDialog-paper {
  margin: 0px !important;
  box-shadow: 0 4px 8px rgba(255, 0, 0, 0.2); /* Red shadow */
  border-radius: 20rem; /* Rounded border */
}

.sigma-alert {
  color: #212529;
  background-color: lighten(saturate(adjust-hue($secondary-color, -1), 6.12), 20.08);
  border-color: $secondary-color;
}

.sigma-warm {
  color: $warm-color;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  margin-top: 0;
  display: block;
}

.lead-title {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  text-align: center;
}

.min-height-inherit {
  min-height: inherit;
}

@media (min-width: 768px) {
  .lead-title {
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 38px;
    text-align: left;
  }

  .sigma-warm {
    font-size: 16px;
    font-weight: 500;
    line-height: 14px;
    display: inline-block;
  }
  a.nav-item.nav-link {
    width: auto;
  }

  .template-item {
    background-color: #f8f8f8;
    border: none;
    height: 100px;
    border-bottom: 1px #eee solid;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.6);
  }

  .task-item {
    border-bottom: none;
    height: 85px;

    .item-date {
      display: block;
      font-size: 16px;
      font-style: normal;
      text-align: left;
      font-weight: 400;
      line-height: 19px;
      display: flex;
      align-items: center;
    }
  }

  .task-detail-btn {
    width: 100%;
    height: auto;
    padding: 5px 25px;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;

    span {
      display: inline-block;
    }
  }

  .db-widget .container-fluid {
    border-right: 2px solid $muted-grey-color;
  }

  .db-widget:nth-child(3) .container-fluid {
    border-right: none;
  }

  .db-widget:last-child .container-fluid {
    border-right: none;
  }
}

@media (min-width: 992px) {
  .side-nav-sigma {
    border-top: none;
  }

  .side-nav-link-sigma {
    font-size: 14px;
    padding: 16px;
  }

  .side-nav-text-sigma {
    display: inline-block;
  }

  .side-nav-icon-sigma svg {
    display: inline-block;
    margin-bottom: auto !important;
  }

  .icon-activity {
    transform: translate(0, -1px);
  }

  .icon-dashboard,
  .icon-salesStream {
    transform: translate(0, -3px);
  }

  .icon-pipeline {
    transform: translate(0, 3px);
  }

  .main-sigma {
    border-radius: 25px 0 0 25px;
    min-height: 100vh;
  }

  .navbar-brand-sigma {
    display: block;
  }
}

.sigma-progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.sigma-progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: $primary-color;
  transition: width 0.6s ease;
}

button.list-group-item-action:focus {
  outline: none;
  background-color: white;
}

.sigma-cool {
  color: $secondary-color;
}

.modal-sigma {
  z-index: 9999;
}

.modal-sigma .modal-header {
  background-color: $primary-color;
  justify-content: center;
}

.modal-sigma .modal-title {
  color: white;
}

.sigma-list dd {
  text-align: right;
}

@media (min-width: 992px) {
  .sigma-list dd {
    text-align: right;
  }
}

a.nav-item.nav-link.active {
  font-weight: bold;
  border-bottom: 2px solid $primary-color;
  border-top: none;
  border-left: none;
  border-right: none;
}

.dot {
  &.primary {
    background-color: rgba($primary-color, 1);
  }
  &.secondary {
    background-color: rgba($secondary-color, 1);
  }
  &.accent-one {
    background-color: rgba($accent-one, 1);
  }
  height: 10px;
  width: 10px;
  margin-right: 8px;
  border-radius: 50%;
  display: inline-block;
}

.h120 {
  height: 120px;
}

.bottom-nav {
  position: absolute;
  bottom: 0;
}

.close.rbt-close {
  margin-top: -32px;
  margin-right: 8px;
}

@media (max-width: 768px) {
  .opportunity-chart {
    width: 415px !important;
  }
  .task-detail-btn {
    padding: 0 !important;

    svg.mr-2 {
      margin-right: 0 !important;
    }
  }
  .list-group-item {
    height: 85px;

    &.lead-item {
      height: 60px;
      padding: 15px 20px;
      font-size: 14px !important;

      .sigma-warm {
        font-size: 10px !important;
      }
      .item-date {
        justify-content: flex-end;
      }
    }
  }

  .template-item {
    background-color: #f8f8f8;
    border: none;
    height: 130px;
    border-bottom: 1px #eee solid;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.6);
  }
}

.edit-lead {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.list-group-item {
  padding: 23px 20px;
}
.list-group-item.active {
  margin-top: 0;
  z-index: 2;
  color: #666666;
  background-color: #fff;
  border-color: #fff;
}
.no-data-display {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .title {
    font-size: 1.1rem;
    margin-top: 0.5rem;
  }
}

.reports-no-data-display {
  div {
    margin-top: 0;
  }
  img {
    height: 100px;
  }
}

.icon-mt-3 {
  margin-top: -3px;
}

.icon-mt-2 {
  margin-top: -2px;
}
.leads-header {
  padding-left: 20px;
  padding-right: 20px;
}

.list-list-container {
  overflow: auto;
}

.fetch-details {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 500px;
  min-height: 500px;
}

.sigma-breadcrumb {
  padding-left: 0px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  list-style: none;
  border-radius: 0.25rem;
}

.sigma-breadcrumb {
  .breadcrumb-item {
    color: black;
    font-weight: bold;
    font-size: 18px;
    a {
      color: black;
      font-weight: bold;
    }
  }
}

.notification-prefs-row {
  background-color: #f8f8f8;
  border: none;
  height: 90px;
  border-bottom: 1px #eee solid;
}

.settings-option-row {
  background-color: #f8f8f8;
  border: none;
  height: 90px;
  border-bottom: 1px #eee solid;
}

.lead-task-show-container {
  display: flex;
  flex-direction: column;

  .lead-task-show-header {
  }

  .lead-task-show-split-sections {
    height: 0;
    flex: 1;
  }
  .lead-task-detail-section {
    padding: 0 16px 0 0;
    overflow-y: auto;
    max-height: 100%;
  }

  .lead-history-tabs-section {
    width: 0;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    // create centered border between details and tab
    // &::before {
    //   content: '';
    //   position: absolute;
    //   height: calc(100% - 50px);
    //   left: 0;
    //   border-left: 1px solid lightgrey;
    // }
  }
}

.lead-create-typeahead {
  input {
    background-color: white;
    border: 1px solid rgb(206, 212, 218);
  }
}

.lead-tabs-header {
  margin: 0 !important;
  a[class^='nav-item'] {
    width: 50% !important;
    z-index: 2;
    background: white;
  }
}

.tab-content {
  height: 95% !important;
}

.tab-pane {
  height: 100% !important;
}

.italic-date {
  margin: 0;
  white-space: nowrap;
  color: $muted-grey-color;
  font-style: italic;
  font-weight: 300;
}

.no-content-communication {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;

  h6 {
    margin: 0;
    white-space: nowrap;
    color: $grey-color;
  }

  div {
    border-top: 2px dashed $muted-grey-color;
    flex-basis: 100%;
    flex-shrink: 1;
    margin: 0 1em 0 1em;
  }
}

@mixin communication-icon($background-color) {
  position: absolute;
  background-color: $background-color;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.3em;
  height: 2em;
  border-radius: 0.4em;
}

.content-communication {
  display: flex;
  position: relative;

  p {
    padding-right: 8px;
  }

  .italic-date {
    margin-left: 45%;
  }

  .message-bubble {
    word-break: break-word;
    font-size: 14px;
    font-weight: 400 !important;
    padding: 1.3em;
    width: 100%;
    background-color: $grey-secondary;
    border-radius: 0.5em;
    margin: 0;
    letter-spacing: 0.06em;
    line-height: 1.3em;
    white-space: pre-wrap;
    display: flex;
  }

  .message-bubble.incoming {
    background-color: lighten(
      saturate(adjust-hue($secondary-color, -1), 6.12),
      20.08
    );
  }
}

.inbound-icon {
  color: $secondary-color;
  background-color: white;
  margin-right: 0.8em;
  svg {
    transform: rotateY(180deg);
  }
  transform: rotateY(180deg);
}

.outbound-icon {
  color: $grey-color;
  background-color: white;
  margin-right: 0.8em;
}

.tasks-back {
  display: flex;
  align-items: center;
  padding: 0;
  position: absolute;
  top: -30px;
  left: 0;
}

.edit-details-fields-list {
  dt,
  dd {
    padding: 0 !important;
  }
}

.task-detail-card {
  border: none !important;
}

.range-select legend {
  width: 0;
}

$source-card-width: 320px;

.source-card {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: $source-card-width;
  height: auto;
  border-radius: 0.4em;
  background: $grey-secondary; //TODO
  max-width: 402px;
  flex-grow: 1;
  &__header {
    flex-basis: 100%;
  }

  &__metrics {
    flex-basis: 100%;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
  }
  &__values-container {
    padding: 0.8em;
    align-self: stretch;
    flex-basis: 50%;

    border-radius: 0.3em;
    background: white;
  }

  .fraction-bar {
    position: relative;

    &::after {
      position: absolute;
      content: '';
      bottom: 0;
      margin-left: auto;
      margin-right: auto;
      right: 0;
      left: 0;
      width: 50%;
      border-bottom: 2px solid $grey-color;
    }
  }
}

.grey-background-box {
  background: $grey-secondary;
  border-radius: 0.3em;
}

.reports-chart {
  height: 140px;
  width: 140px;
}

.chart-no-data {
  width: 237px;
}

.source-doughnut-labels {
  height: 140px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 4px;
  p {
    font-size: 14px;
    line-height: 20px;
  }
}

@media screen and (max-width: 576px) {
  .source-doughnut-labels {
    overflow-x: auto;
  }
}

.piechart-label-square {
  height: 15px;
  width: 15px;
}

.conversion-rate-tooltip {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.public-DraftStyleDefault-block {
  margin: 0;
}

.editor-class {
  min-height: 150px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  padding: 0.375rem 0.75rem;
}

.email-modal {
  max-width: none;
  width: 80vw;
}
@media screen and (max-width: 992px) {
  .email-modal {
    width: 90vw;
  }
}

@media screen and (max-width: 576px) {
  .email-modal {
    width: 97vw;
  }
}

.email-communication-history {
  @include pretty-scroll;

  overflow-y: auto;
  max-height: 500px;
}

.template-modal {
  max-width: 50vw;
}

@media screen and (max-width: 992px) {
  .template-modal {
    max-width: 95vw;
  }
}

.sequence-template-preview {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.option-dropdown {
  max-width: 220px;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.dropdown-item:active {
  background-color: transparent;
  color: initial;
}

.filter-dropdown-displays {
  max-width: min(250px, 25vw);
  overflow-x: hidden;
  text-overflow: ellipsis;
}
